import { captureMessage, init } from '@sentry/gatsby';

import { isMonitoringEnabled } from 'utils/featureFlags';
import { isNumber } from 'utils/functions';

interface ErrorFilter {
  name?: string;
  message?: string;
}

const FILTERED_ERRORS: ErrorFilter[] = [
  {
    name: 'NotAllowedError',
    message: 'Permission to join interest group denied',
  },
];

export class SentryService {
  private static isInitialized = false;

  private static shouldFilterError(error: Error): boolean {
    return FILTERED_ERRORS.some((filter) => {
      if (filter.name) {
        if (error.name !== filter.name) {
          return false;
        }
      }

      if (filter.message) {
        if (!error.message.includes(filter.message)) {
          return false;
        }
      }

      return true;
    });
  }

  static init(): void {
    if (typeof window === 'undefined' || this.isInitialized) {
      return;
    }

    const sampleRate = +process.env.GATSBY_SENTRY_SAMPLE_RATE!;
    const tracesSampleRate = isNumber(sampleRate) ? sampleRate : 0.01;

    init({
      tracesSampleRate,
      dsn: process.env.GATSBY_SENTRY_DSN,
      attachStacktrace: true,
      enabled: isMonitoringEnabled(),
      denyUrls: [
        /stats\.g\.doubleclick\.net/,
        /googletagmanager\.com\/gtm\.js/,
        /cdn\.cookielaw\.org/,
        /tdn\.r42tag\.com/,
      ],
      beforeSend(event, hint) {
        if (hint?.originalException instanceof Error) {
          if (SentryService.shouldFilterError(hint.originalException)) {
            return null;
          }
        }

        return event;
      },
    });

    this.isInitialized = true;
  }

  static captureError({ context, message }: { context: any[]; message: string }): void {
    captureMessage(message, {
      level: 'error',
      extra: { context },
    });
  }
}
