import DOMPurify from 'isomorphic-dompurify';

import { BreadcrumbI } from 'interfaces/content-stack/contentStack.types';

import { HOMEPAGE_TITLE, PAGE_TITLE } from 'utils/constants';

export function checkButtonWidth(value: number) {
  if (value) {
    if (value === 100) {
      return 100 + '%';
    } else {
      return value + 'rem';
    }
  }

  return;
}

export function calculateOffset(value: number) {
  if (value) {
    return 100 - value + '%';
  } else {
    return null;
  }
}

export function checkHeaderType(type = 'H2') {
  if (type === PAGE_TITLE) {
    return 'h2';
  } else if (type === HOMEPAGE_TITLE) {
    return 'h1';
  } else {
    return type.toLowerCase();
  }
}

export function buildHeader(header: string, type: string) {
  if (type === PAGE_TITLE) {
    const splitHeader = DOMPurify.sanitize(header).split('/');

    return splitHeader.join('<span>/</span>');
  } else if (type === HOMEPAGE_TITLE) {
    const splitHeader = header.split('/');
    const finalSplitHeader: string[] = [];
    splitHeader.forEach((item) => {
      const headerText = '<span class="headline-text">' + item + '</span>';
      finalSplitHeader.push(headerText);
    });

    return finalSplitHeader.join('<span>/</span>');
  } else {
    return header;
  }
}

export function buildBasicRows(columns: any[]) {
  if (columns.length < 1) return [];

  // Get the number of rows and assume all columns have same number of rows
  const numRows = columns[0]?.rows?.length;

  // Build empty array based on number of rows
  const emptyRows = [];
  for (let i = 0; i < numRows; i++) {
    emptyRows.push([]);
  }

  // Build out html rows to number of columns
  const htmlRows = columns.reduce((rows, column) => {
    for (let i = 0; i < column.rows.length; i++) {
      rows?.[i]?.push(column.rows[i]);
    }
    return rows;
  }, emptyRows);

  return htmlRows;
}

export function buildComparisonRows(columns: { rows: any[] }[] = [], headers: any[] = []) {
  const firstHeader = headers[0];
  const firstColumn = columns[0];

  const comparisonRows: { [key in any]: any }[] = [];
  columns?.slice(1)?.forEach((column, index) => {
    const { rows = [] } = column;

    comparisonRows.push({
      name: firstHeader.content,
      data: headers.slice(1)[index].content,
      header: true,
    });

    rows?.forEach((row, index) => {
      const title = firstColumn?.rows[index]?.content?.replace(/<[^>]*>?/gm, '');
      const formattedContent = row?.content?.replace(/<[^>]*>?/gm, '');

      comparisonRows.push({ name: title, data: formattedContent });
    });
  });

  return comparisonRows;
}

// allow for proper escaping of html elements when building json-ld
export function getRawText(text: string) {
  let rawText = text.replace(/<[^>]*>/g, ' '); //Replaces <[^>]*> with an empty string
  rawText = rawText.replace(/\s+/g, ' '); //Replaces \s+ with an empty string
  rawText = rawText.replace(/&nbsp;/g, ' '); //Replaces &nbsp; with a space
  rawText = rawText.replace(/&#x27;/g, "'"); //Replaces &#x27; with apostrophe
  rawText = rawText.trim();
  return rawText;
}

export function autoOptimizeImage(url?: string, optimizeSize = false, width?: string) {
  if (url) {
    let optimizedImage = `${url}?format=pjpg&auto=webp&quality=90`;

    if (optimizeSize && width) {
      optimizedImage += `&width=${width}&disable=upscale`;
    }

    return optimizedImage;
  }

  return url;
}

export function optimizeImageHeight(url?: string, height?: string) {
  if (url) {
    let optimizedImage = `${url}?format=pjpg&auto=webp`;

    if (height) {
      optimizedImage += `&height=${height}&disable=upscale`;
    }

    return optimizedImage;
  }

  return url;
}

// This function is expecting date params to be passed in that are using
// the native JavaScript Date() object
export function isSameDate(day1: Date, day2: Date) {
  return (
    day1.getUTCFullYear() === day2.getUTCFullYear() &&
    day1.getUTCMonth() === day2.getUTCMonth() &&
    day1.getUTCDate() === day2.getUTCDate()
  );
}

export const calcPercent = (value1: number, value2: number) => {
  const percent = (value1 / value2) * 100;
  return Math.round(percent);
};

export const createHeaderId = (string: string) => {
  return string.replace(/\s+/g, '-').toLowerCase();
};

export function dataLayerPush(message: string, dataKey = 'event', additionalData = {}) {
  window.dataLayer?.push({ [dataKey]: `${message}`, ...additionalData });
}

export const addMaximumScaleToMetaViewport = () => {
  const el = document.querySelector('meta[name=viewport]');

  if (el !== null) {
    let content: string | null = el.getAttribute('content');
    const re = /maximum\-scale=[0-9\.]+/g;

    if (content && re.test(content)) {
      content = content.replace(re, 'maximum-scale=1.0');
    } else {
      content = [content, 'maximum-scale=1.0'].join(', ');
    }

    el.setAttribute('content', content);
  }
};

export function compareNumbers(num1: number, num2: number): number {
  if (num1 === num2) {
    return 0;
  } else if (num1 > num2) {
    return -1;
  } else {
    return 1;
  }
}

export function findVideoExtension(video?: { url: string }) {
  return video?.url.split('.').pop() ?? null; // filetype restriction on CS - guaranteed to be mp4 or webm
}

export function buildBreadcrumbUrls(breadcrumb: BreadcrumbI[], url: string) {
  const urlPrefix = breadcrumb.map((crumb) => crumb.url).join('/');
  const pageUrl = `/${urlPrefix}/${url}`;

  return pageUrl.replaceAll(/\/+/g, '/');
}

export const isString = (value: any): boolean => typeof value === 'string';

export const isNumber = (value: any): boolean => typeof value === 'number' && !isNaN(value);
