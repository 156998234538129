export const IP_COUNTRY_API_URL = 'https://api.country.is/';
export const HOMEPAGE_TITLE = 'Homepage Title';
export const PAGE_TITLE = 'Page Title';
export const NAV_BREAKPOINT = 991;
export const NEW_USER = 'new';
export const RETURN_USER = 'return';
export const AUTH_USER = 'authorized';
export const ONETRUST_DOMAIN_ID = process.env.GATSBY_ONETRUST_DOMAIN_ID || '018f2533-da38-7b99-a818-b0df21c23f09-test';
export const GATSBY_SOURCE_CONTENTSTACK_PREFIX = 'Contentstack_';

export const CONTENT_ALIGN = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end',
};

export const MOBILE_CONTENT_ALIGN = {
  left: 'left',
  right: 'right',
  center: 'center',
};

export const COLUMN_SPAN_OPTS = {
  fullWidth: 'full width',
  halfWidth: 'half width',
  twoThirds: '2/3 width',
  oneThird: '1/3 width',
  oneFourth: '1/4 width',
  threeFourth: '3/4 width',
  oneSixth: '1/6 width',
  fiveTwelths: '5/12 width',
  sevenTwelths: '7/12 width',
  oneTwelth: '1/12 width',
};

export const columnSpanMap = {
  [COLUMN_SPAN_OPTS.fullWidth]: 12,
  [COLUMN_SPAN_OPTS.halfWidth]: 6,
  [COLUMN_SPAN_OPTS.twoThirds]: 8,
  [COLUMN_SPAN_OPTS.oneThird]: 4,
  [COLUMN_SPAN_OPTS.oneFourth]: 3,
  [COLUMN_SPAN_OPTS.threeFourth]: 9,
  [COLUMN_SPAN_OPTS.oneSixth]: 2,
  [COLUMN_SPAN_OPTS.fiveTwelths]: 5,
  [COLUMN_SPAN_OPTS.sevenTwelths]: 7,
  [COLUMN_SPAN_OPTS.oneTwelth]: 1,
};

export const disclaimerTypes = {
  DISCLAIMER: 'disclaimer',
  FOOTNOTE: 'footnote',
};

export const DEVICE_VISIBILITY = {
  mobile_only: 'mobile-only',
  desktop_only: 'desktop-only',
};

export const headerMenuSpecialBehavior = {
  help: 'help',
  login: 'log_in',
  openAnAccount: 'open_an_account',
  textSnippet: 'text_snippet',
  divider: 'divider',
};
